import React from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const Demopdf = () => {
    const generatePdf = async (): Promise<Blob> => {
        const htmlContent = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Invoice</title>
          <style>
              body {
                  font-family: Arial, sans-serif;
                  margin: 0;
                  padding: 0;
                  background-color: #f8f9fa;
              }
              .invoice-box {
                  max-width: 800px;
                  margin: auto;
                  padding: 20px;
                  border: 1px solid #ddd;
                  background-color: #fff;
                  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
              }
              .invoice-header {
                  text-align: center;
                  margin-bottom: 20px;
              }
              .invoice-header h1 {
                  margin: 0;
              }
              .invoice-header p {
                  margin: 0;
                  font-size: 14px;
                  color: #888;
              }
              .invoice-details {
                  margin-bottom: 20px;
              }
              .invoice-details .detail {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 10px;
              }
              .invoice-details .detail span {
                  font-size: 16px;
              }
              .invoice-details .detail .label {
                  font-weight: bold;
              }
              .product-list {
                  width: 100%;
                  border-collapse: collapse;
                  margin-bottom: 20px;
              }
              .product-list th, .product-list td {
                  padding: 10px;
                  border: 1px solid #ddd;
                  text-align: left;
              }
              .product-list th {
                  background-color: #f1f1f1;
              }
              .total {
                  text-align: right;
                  font-size: 18px;
                  font-weight: bold;
              }
              .payment-info {
                  margin-top: 20px;
              }
              .payment-info .detail {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 10px;
              }
          </style>
      </head>
      <body>
      <div class="invoice-box">
          <div class="invoice-header">
              <h1>Invoice</h1>
              <p>Company Name: <strong>Imetanic LLP</strong></p>
          </div>
          <div class="invoice-details">
              <div class="detail">
                  <span class="label">Customer Name:</span>
                  <span>John Doe</span>
              </div>
              <div class="detail">
                  <span class="label">Order Date:</span>
                  <span>01-08-2024</span>
              </div>
          </div>
          <table class="product-list">
              <thead>
                  <tr>
                      <th>S No</th>
                      <th>Product Name</th>
                      <th>Quantity</th>
                      <th>Total</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td>1</td>
                      <td>Product 1</td>
                      <td>2</td>
                      <td>50 QAR</td>
                  </tr>
                  <tr>
                      <td>2</td>
                      <td>Product 2</td>
                      <td>1</td>
                      <td>25 QAR</td>
                  </tr>
              </tbody>
          </table>
          <div class="total">
              <span>Total Amount: 75 QAR</span>
          </div>
          <div class="payment-info">
              <div class="detail">
                  <span class="label">Received Amount:</span>
                  <span>75 QAR</span>
              </div>
              <div class="detail">
                  <span class="label">Remaining Amount:</span>
                  <span>0 QAR</span>
              </div>
              <div class="detail">
                  <span class="label">Payment Mode:</span>
                  <span>Cash</span>
              </div>
              <div class="detail">
                  <span class="label">Order Status:</span>
                  <span>Paid</span>
              </div>
          </div>
      </div>
      </body>
      </html>
    `;

        const container = document.createElement('div');
        container.style.position = 'fixed'; // Prevent layout shift
        container.style.top = '0';
        container.style.left = '0';
        container.style.zIndex = '-9999';
        container.innerHTML = htmlContent;
        document.body.appendChild(container);

        const canvas = await html2canvas(container);
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210; // A4 width in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

        const pdfBlob = pdf.output('blob');
        document.body.removeChild(container); // Clean up the DOM

        return pdfBlob;
    };

    const sendPdfViaEmail = async (download = false) => {
        const pdfBlob = await generatePdf();
        if (download) {
            const url = window.URL.createObjectURL(pdfBlob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'sample.pdf';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } else {
            const formData = new FormData();
            formData.append('file', pdfBlob, 'sample.pdf');
            await fetch(`/app/v1/pos/orders_send_email`, {
                method: 'POST',
                body: formData,
            });
            alert('PDF sent via email!');
        }
    };

    return (
        <div>
            <button onClick={() => sendPdfViaEmail(false)}>Send PDF via Email</button>
            <button onClick={() => sendPdfViaEmail(true)}>Download PDF</button>
        </div>
    );
};

export default Demopdf;
