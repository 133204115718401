import React, { useEffect, useRef, useState } from "react";
import "./Home.css";
import User from "../../assets/Images/user1.png";
import { useNavigate } from "react-router-dom";
import { Flip, ToastContainer, toast } from "react-toastify";
import passwords from "../../assets/Images/possword.png";
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Loadingscr from "../Loading/Loadingscr";
import api from "../axiosConfig";
interface themes {
  background_color: string;
  font_color: string;
  font_family: string;
}
const Login = () => {
  const navigate = useNavigate();
  const [Loading, setLoading] = useState<boolean>(false);
  const [user_name, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [visible, setvisible] = useState<boolean>(false);
  const [employees, setemployee] = useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const passwordRef = useRef<HTMLInputElement | null>(null);

  const handleUsernameKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      passwordRef.current?.focus();
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    document.title = "Login - iMetanic";
    setLoading(true);
    const employee = sessionStorage.getItem("employee")
    const nonemployee = sessionStorage.getItem("Noemployee")
    if (!sessionStorage.getItem("jsonwebtoken")) {
      navigate("/");
    } else {
      if (employee || employees === true) {
        console.log("data 1");
        navigate("/v1/employee/home");
      } else if (nonemployee || employees === false) {
        navigate("/v1/home");
        console.log("data 2");
      }
    }
    setLoading(false);
  }, [employees, navigate]);


  useEffect(() => {
    const business_type = sessionStorage.getItem("business_type");

    if (!sessionStorage.getItem("jsonwebtoken")) {
      navigate("/");
    } else {
      if (business_type === "Laundry") {
        navigate("/v1/home");
      } else if (business_type === "Restaurant") {
        navigate("/v1/Reshome");
      }
    }
  }, [navigate]);



  useEffect(() => {
    if (user_name && password) {
      setvisible(true);
    } else {
      setvisible(false);
    }
  }, [password, user_name]);

  const handlekeypress = (event: { key: string }) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };
  const handleLogin = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("user_name", user_name);
      formData.append("password", password);
      const response = await api.post(
        `/pos/login`,
        formData
      );
      if (response.status === 200) {
        const { token } = response.data;
        sessionStorage.setItem("jsonwebtoken", token);
        api.get(`/pos/profile`).then((response) => {
          const employee = response.data.message.employee_name
          api.get(`/pos/get_user`)
            .then((response) => {
              const business_type = response.data.result[0].business_type;
              console.log('business_type', response.data.result[0]);
              sessionStorage.setItem("business_type", business_type);
              console.log('response65765757', response);
              if (business_type === "Laundry") {
                sessionStorage.setItem("laundry", "Laundry_login");
                if (employee !== undefined) {
                  setemployee(true)
                  sessionStorage.setItem("employee", 'login_employee');
                  setTimeout(() => {
                    navigate("/v1/employee/home");
                  }, 2000);
                } else {
                  console.log('working');
                  setemployee(false)
                  sessionStorage.setItem("Noemployee", 'login_employee');
                  setTimeout(() => {
                    navigate("/v1/home");
                  }, 2000);
                }
              } else if (business_type === "Restaurant") {
                sessionStorage.setItem("restaurant", "Restaurant_login");
                navigate("/v1/Reshome");
              }
            })
          
          console.log('employee', employee);
         

          const profilename = response.data.message.user_name;
          sessionStorage.setItem("Profilename", profilename)
          const organization_name = response.data.message.organization_name;
          sessionStorage.setItem("organization_name", organization_name);
          const business_phone = response.data.message.phone_number;
          sessionStorage.setItem("business_phone", business_phone);
          
          const imgs = response.data.message.filename;
          if (imgs) {
            sessionStorage.setItem(`imgs${organization_name}`, imgs);
            const img = response.data.message.organization_name + ".png";
            api.get(`/pos/logo/${img}`, { responseType: "arraybuffer" })
              .then((response) => {
                console.log("img", response);
                const blob = new Blob([response.data], { type: "image/png" });
                sessionStorage.setItem(
                  `Logo${organization_name}`,
                  URL.createObjectURL(blob)
                );
              })
          }
          api.get(`/pos/profile_images/${profilename}.png`, { responseType: "arraybuffer" })
            .then((response) => {
              console.log("img", response);
              const blob = new Blob([response.data], { type: 'image/png' });
              sessionStorage.setItem(`Profilelogo${profilename}`, URL.createObjectURL(blob));
            }).catch((error) => {
              console.log('err', error);
            })

          api.get(`/pos/theme`)
            .then((res) => {
              const theme: themes[] = res.data.result;
              const getTheme = theme.flatMap(item => item.background_color)
              const getfontcolor = theme.flatMap(item => item.font_color)
              const getfontfamily = theme.flatMap(item => item.font_family)
              console.log(getTheme);
              if (getTheme.length > 0) {
                sessionStorage.setItem(`background_color${organization_name}`, String(getTheme))
                sessionStorage.setItem(`font_color${organization_name}`, String(getfontcolor))
                sessionStorage.setItem(`font_family${organization_name}`, String(getfontfamily))
              }
              else {
                const data = {
                  background_color: '#6495ED',
                  font_color: 'white',
                  font_family: 'Franklin Gothic Medium',
                }
                api.post(`/pos/theme`, data).then((response) => {
                  sessionStorage.setItem(`background_color${organization_name}`, String('#6495ED'))
                  sessionStorage.setItem(`font_color${organization_name}`, String('white'))
                  sessionStorage.setItem(`font_family${organization_name}`, String('Franklin Gothic Medium'))
                })
              }
            }).catch((error) => {
              console.error(error);
              sessionStorage.setItem(`background_color${organization_name}`, String('#6495ED'))
              sessionStorage.setItem(`font_color${organization_name}`, String('white'))
              sessionStorage.setItem(`font_family${organization_name}`, String('Franklin Gothic Medium'))
            })
        });
        toast.success("Logged in successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Flip,
        });
       
      } else {
        console.error("Login failed:", response.data.message);
        toast.error("Login failed", {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Flip,
        });
      }
    } catch (error: any) {
      console.error("Error during login:", error.message);
      toast.error("Logged in failed", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
    }
    setLoading(false);
  };
  const loginbtn = () => {
    if (visible === true) {
      return (<button className="btns1" id="login" onClick={handleLogin}  >
        Login
      </button>)
    } else {
      return (<button className="btnsdisabled " id="login" disabled  >
        Login
      </button>)
    }
  }

  return (
    <div className="signall" id="signup_allpage">
      <div className="colarcontainer-1" id="Left_small_container"></div>
      <div className="Container1">
        <div style={{ width: "600px" }}>
          <h1>Get’s Started</h1>
          <div>
            <div className="inbox">
              <FormControl sx={{ m: 2 }} variant="standard">
                <InputLabel
                  htmlFor="standard-adornment-password"
                  style={{ display: "flex" }}>
                  <img src={User} className="imgs" alt="" />
                  <p>Username</p>
                </InputLabel>
                <Input
                  id="User name"
                  className="inputuser"
                  onKeyPress={handleUsernameKeyPress}
                  value={user_name}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </FormControl>
            </div>
          </div>
          <div>
            <div className="inbox">
              <FormControl sx={{ m: 2 }} variant="standard">
                <InputLabel
                  htmlFor="standard-adornment-password"
                  style={{ display: "flex" }}>
                  <img src={passwords} className="imgs" alt="" />
                  <p>Password</p>
                </InputLabel>
                <Input
                  id="Password"
                  className="inputuser"
                  onKeyPress={handlekeypress}
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        id="standard-adornment-password"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  inputRef={passwordRef}
                />
              </FormControl>
            </div>
          </div>
          <div className="signbtns">
            {loginbtn()}
          </div>
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <a href="/forgotpassword" style={{ textDecoration: "none", color: "#007BFF" }}>
              Forgot Password?
            </a>
          </div>
        </div>
        <ToastContainer />
      </div>
      {Loading ? <Loadingscr /> : ""}
      <div className="Colorcontainer"></div>
    </div>
  );
};

export default Login;