import React, { useEffect, useState } from "react";
import Category from "../Sub page/Category/Category";
import Header from "./Header";
import Menu from "./Menu";
function Home() {
  const [fontFamily,setfontfamily]= useState("");
  useEffect(() => {
    document.title = "Home - iMetanic";
    const business = sessionStorage.getItem("organization_name");
    const fontfamily = sessionStorage.getItem(`font_family${business}`);
    if (fontfamily) {
      setfontfamily(fontfamily)
    }
  }, []);
  return (
    <div style={{ display: "flex", flexDirection: 'column',overflow:'hidden',fontFamily:fontFamily }}>
      {/* <div style={{backgroundColor:'black',color:'white'}}>
      <Header Header={"Point of Sale"} />
      <div style={{display:'flex'}}>
        <Menu />
        <div>
          <Category />
        </div>
      </div>
      </div> */}
      <div>
      <Header Header={"Point of Sale"} />
      <div style={{display:'flex'}}>
        <Menu />
        <div>
          <Category />
        </div>
      </div>
      </div>
    </div>
  );
}

export default Home;
