import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from "recharts";

interface Props {
  Orders: number | null;
  amount: number | null;
  ReceivedAmount: number | null;
}

const RePieChart: React.FC<Props> = ({ Orders, amount, ReceivedAmount }) => {
  const data = [
    { name: "Orders", value: Orders || 0 },
    { name: "Amount", value: amount ? parseFloat(amount.toFixed(2)) : 0 },
    { name: "Received Amount", value: ReceivedAmount ? parseFloat(ReceivedAmount.toFixed(2)) : 0 },
  ];

  const colors = ["#4e73df", "#1cc88a", "#f6c23e"];

  const renderCustomizedLabel = ({ cx, cy, midAngle, outerRadius, name, value }: any) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 20;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="#333"
        textAnchor={x > cx ? "start" : "end"}
        fontSize="14px"
        fontWeight="bold"
        dy={0}
      >
        {`${name}: ${name === "Orders" ? value : value.toFixed(2)}`}
      </text>
    );
  };

  return (
    <div className="chart-container">
      <h3 className="chart-title">Order Summary</h3>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius="45%"
            outerRadius="60%"
            labelLine={false}
            label={renderCustomizedLabel} 
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} stroke="#fff" strokeWidth={2} />
            ))}
          </Pie>
          <Tooltip
            contentStyle={{
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
              fontSize: "14px",
            }}
            formatter={(value: number, name: string) => 
              name === "Orders" ? value : value.toFixed(2)}
          />
          <Legend
            verticalAlign="bottom"
            height={36}
            iconType="circle"
            iconSize={10}
            wrapperStyle={{ color: "#666", fontSize: "13px", textAlign: "center" }}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RePieChart;
