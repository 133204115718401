import React, { useEffect, useState } from 'react'
import CloseIcon from "@mui/icons-material/Close";
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Buttons from '../../Common_compound/Buttons';
import { Checkbox, IconButton, Tooltip } from '@mui/material';
import api from '../../axiosConfig';
import { Flip, toast, ToastContainer } from 'react-toastify';

interface Props {
    id: string;
}
interface userpage {
    _id: { $oid: string };
    employee_name: string;
    phone_number: string;
    date_of_joined: string;
    address: string;
}
const Edituser: React.FC<Props> = ({ id }) => {
    const [name, setName] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [oldname, setoldName] = useState<string>("");
    const [oldphone, setoldphone] = useState<string>("");
    const [joiningDate, setjoiningDate] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [oldJoiningDate, setOldJoiningDate] = useState<string>("");
    const [oldAddress, setOldAddress] = useState<string>("");
    const [inputValue, setInputValue] = useState<string>("");
    const [dobError, setDobError] = useState<string | null>(null);
    const [visible, setvisible] = useState<boolean>(false);
    useEffect(() => {
        console.log("id", id);
        api.get(`/pos/add/${id}`)
            .then((res) => {
                const userData: userpage = res.data.message;
                console.log(userData?.employee_name);
                setName(userData?.employee_name);
                setPhone(userData?.phone_number);
                setoldphone(userData?.phone_number);
                setoldName(userData?.employee_name);
                setjoiningDate(userData?.date_of_joined);
                setOldJoiningDate(userData?.date_of_joined);
                setAddress(userData?.address);
                setOldAddress(userData?.address);
                setInputValue(userData?.date_of_joined || "");
            })
            .catch((err) => console.error(err));
    }, [id])
    const handleDateValidation = (dateString: string): boolean => {
        const dateParts = dateString.split('-');
        const day = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10);
        const year = parseInt(dateParts[2], 10);
        if (month < 1 || month > 12) {
            setDobError("Month must be between 01 and 12.");
            return false;
        }
        const daysInMonth = new Date(year, month, 0).getDate();
        if (day < 1 || day > daysInMonth) {
            setDobError(`Day must be between 01 and ${daysInMonth}.`);
            return false;
        }

        setDobError(null);
        return true;
    };
    const handleusers = () => {
        const dateRegex =/^\d{2}-\d{2}-\d{4}$/;
        if (!dateRegex.test(joiningDate) || !handleDateValidation(joiningDate)) {
            setDobError("Date of joining must be in dd-mm-yyyy format.");
            return;
        } else {
            setDobError(null);
        }
        const dateParts = joiningDate.split('-');
        const day = dateParts[0];
        const month = dateParts[1];
        const year = dateParts[2];

        const update = {
            employee_name: name,
            phone_number: phone,
            date_of_joined: `${day}/${month}/${year}`,
            address: address,
        }
        api.put(`/pos/add/${id}`, update, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                toast.success("User updated successfully", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch((err) => {
                toast.error("Error updating user", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                console.error(err);
            });
    }
    useEffect(() => {
        if (name !== oldname || phone !== oldphone || joiningDate !== oldJoiningDate || address !== oldAddress) {
            if (phone.length === 8) {
                setvisible(true);
            } else { setvisible(false); }
        } else {
            setvisible(false);
        }
    }, [name, oldname, oldphone, phone, joiningDate, oldJoiningDate, address, oldAddress]);
    const GButton = {
        Blue: "blue",
        name: "update",
    };
    const FButton = {
        Cancel: "red",
        name: "Cancel",
    };
    const KButtonerr = {
        error: "blue",
        name: "update",
    };
    const confirm = () => {
        if (visible === true) {
            return (
                <div onClick={handleusers}>
                    <Buttons Buttondata={GButton} />
                </div>
            )
        } else {
            return (
                <div id="confirm_btn">
                    <Buttons Buttondata={KButtonerr} />
                </div>
            )
        }
    }
    return (
        <div>
            <div className="modals">
                <div className="overlays"></div>
                <div className="modal-confirm">
                    <div className="edit_userpop">
                        <div>
                            <div className='Edit_header'>
                                <h2>Edit user</h2>
                            </div>
                            <div>
                                <button
                                    onClick={() => window.location.reload()}
                                    className="cancel-icon-Edituser"
                                >
                                    <Tooltip title="Close" placement="bottom" arrow>
                                        <IconButton>
                                            <CloseIcon />
                                        </IconButton>
                                    </Tooltip>
                                </button>
                            </div>
                            <div className='edit_textfield'>
                                <TextField
                                    id="outlined-basic"
                                    disabled
                                    label={
                                        <span>
                                          Name <span style={{ color: '#f04141', fontSize: "15px" }}>*</span>
                                        </span>
                                      }
                                    variant="outlined"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <TextField
                                    sx={{ marginLeft: "30px" }}
                                    id="outlined-basic"
                                    label={
                                        <span>
                                          Phone number <span style={{ color: '#f04141', fontSize: "15px" }}>*</span>
                                        </span>
                                      }
                                    variant="outlined"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                                <TextField
                                    error={!!dobError}
                                    sx={{ marginTop: "20px", width: "222px" }}
                                    label="Date of joining"
                                    value={joiningDate}
                                    variant="outlined"
                                    helperText={dobError}
                                    onChange={(e) => setjoiningDate(e.target.value)}
                                />
                                <TextField
                                    sx={{ marginLeft: "30px", marginTop: "20px" }}
                                    id="outlined-basic"
                                    label="Address"
                                    variant="outlined"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                                {/* <div className='Role_option'>
                                    <h3 className='Role_option_edit'>
                                        <div className='Mahi'>
                                            <p>
                                                Role
                                            </p>
                                            <p style={{ color: "red" }}>*</p>
                                        </div>
                                        <FormControlLabel
                                            required
                                            control={<Checkbox />}
                                            label="Admin"
                                        />
                                        <FormControlLabel
                                            required
                                            control={<Checkbox />}
                                            label="Employee"
                                        />
                                    </h3>
                                </div> */}
                            </div>
                            <div className='btn_group'>
                                <div onClick={() => window.location.reload()}>
                                    <Buttons Buttondata={FButton} />
                                </div>
                                {/* <div onClick={handleusers}>
                                    <Buttons Buttondata={GButton} />
                                     {confirm()}
                                </div> */}
                                <div onClick={handleusers}>
                                    {visible ? (
                                        <Buttons Buttondata={GButton} />
                                    ) : (
                                        <Buttons Buttondata={KButtonerr} />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Edituser