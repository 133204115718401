import React from "react";
import Buttons from "../../Common_compound/Buttons";
import api from "../../axiosConfig";
import { Flip, toast, ToastContainer } from "react-toastify";

interface Props {
  id: string | undefined;
  name: string;
}

const Deletescreen: React.FC<Props> = ({ id, name }) => {
  const Deletescreen = () => {
    api.delete(`/app/v1/pos/customer/${id}`).then((res) => {
      toast.success("Customer deleted successfully", { 
        position: "top-right",
        autoClose: 2100,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2000); 
    });
  };
  const GButton = {
    Blue: "blue",
    name: "confirm",
  };
  const PButton = {
    Cancel: "red",
    name: "Cancel",
  };
  return (
    <div className="modals">
      <div className="overlays"></div>
      <div className="modal-contents">
        <div className="edit-popupscreen">
          <div className="addcategory-edit-product" style={{ marginTop: "-20px" }}>
            <h1 style={{ marginTop: "20px" }}>Confirm to delete</h1>
            {name && <p style={{ fontWeight: 'bold', marginTop: "15px" }}>Customer name : {name}</p>}
            <p style={{ marginTop: "20px" }}>Are you sure you want to delete this customer?</p>
            <div style={{ display: "flex", width: "400px" }}>
              <div className="btnconfirm-edit">
              <div
                onClick={() => window.location.reload()}
                style={{ marginRight: "5px" }}>
                <Buttons Buttondata={PButton} />
              </div>
              <div onClick={() => Deletescreen()}>
                <Buttons Buttondata={GButton} />
              </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default Deletescreen;
