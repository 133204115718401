import React, { useEffect } from "react";
import "./Edit.css";
import { Link } from "react-router-dom";
interface Props {
  editmenu: any | undefined;
}
const Editmenu: React.FC<Props> = ({ editmenu }) => {
  useEffect(() => {
    // const user = sessionStorage.getItem("Profilename");
    const bussness = sessionStorage.getItem("organization_name")
    const color1 = sessionStorage.getItem(`background_color${bussness}`);
    const color2 = sessionStorage.getItem(`font_color${bussness}`);
    const fontfamily = sessionStorage.getItem(`font_family${bussness}`);
    if (color1) { document.documentElement.style.setProperty("--background-color", color1); }
    if (color2) { document.documentElement.style.setProperty("--color", color2); }
    if (fontfamily) { document.documentElement.style.setProperty("--ffamily", fontfamily); }
  }, []);
  return (
    <ul className="edit-menu">
      {editmenu?.Edit ? (
        <div className="selected_Edit">Branches</div>
      ) : (
        <Link to={"/v1/Edit/Location"} className="btn-edit">
          Branches
        </Link>
      )}
      {editmenu?.User ? (
        <div className="selected_Edit">Users</div>
      ) : (
        <Link to={"/v1/Edit/addagent"} className="btn-edit">
          Users
        </Link>
      )}
      {editmenu?.Category ? (
        <div className="selected_Edit">Categories</div>
      ) : (
        <Link to={"/v1/Edit/addcategory"} className="btn-edit">
          Categories
        </Link>
      )}
      {editmenu?.Product ? (
        <div className="selected_Edit">Products</div>
      ) : (
        <Link to={"/v1/Edit/addproduct"} className="btn-edit">
          Products
        </Link>
      )}
      {editmenu?.Customer ? (
        <div className="selected_Edit">Customers</div>
      ) : (
        <Link to={"/v1/Edit/Customerdata"} className="btn-edit">
          Customers
        </Link>
      )}
    </ul>
  );
};

export default Editmenu;
