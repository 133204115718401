import React, { ReactNode, useEffect, useRef, useState } from "react";
import "./Edit.css";
import Editmenu from "./Editmenu";
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import Buttons from "../../Common_compound/Buttons";
import { Flip, toast, ToastContainer } from "react-toastify";
import api from "../../axiosConfig";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import { City, Country, State } from "country-state-city";

import EditBranch from "./Editbranch";
interface Branch {
  _id: { $oid: string };
  Branch_name: string;
  Branch_Opening_date: string;
  Address: {
    address1: string;
    street: string;
    selectedCountry: ReactNode;
    selectedState: ReactNode;
    selectedCity: ReactNode;
    Pincode: string;
  };
  contact_person: string | null;
  branch_number: string | null;
}

function Edit() {
  const [address1, setAddress1] = useState("");
  const [branchopeningdate, setBranchopeningdate] = useState("");
  const [street, setStreet] = useState("");
  const [Location, setAddLocation] = useState<boolean>(false);
  const [Pincode, setPincode] = useState<string>("");
  const [Branch, setBranch] = useState<Branch[]>([]);
  const [Branchname, setBranchname] = useState<string>("");
  const [selectedBranchId, setSelectedBranchId] = useState<string | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [stateerror, setErrorstate] = useState<boolean>(false);
  const [secqire, setSecqire] = useState<boolean>(false);
  const [visible, setvisible] = useState<boolean>(false);
  const [edit, setedit] = useState<boolean>(false);
  const [Id, setId] = useState("");
  const [error, setError] = useState<string>("");
  const [errors] = useState<boolean>(false);
  const addressline1 = useRef<HTMLInputElement | null>(null);
  const streetnames = useRef<HTMLInputElement | null>(null);
  const Countryref = useRef<HTMLInputElement | null>(null);
  const Stateref = useRef<HTMLInputElement | null>(null);
  const Cityref = useRef<HTMLInputElement | null>(null);
  const pincoderef = useRef<HTMLInputElement | null>(null);
  const branchopeningdateref = useRef<HTMLInputElement | null>(null);
  const cardRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [selectedCountry, setSelectedCountry] = useState<string>('');
  const [selectedState, setSelectedState] = useState<string>('');
  const [selectedCity, setSelectedCity] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [doberror, setdobError] = useState<string | null>(null);
  const [dropdownVisible, setDropdownVisible] = useState<{
    [key: string]: boolean;
  }>({});

  const handleBranchnamesKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      branchopeningdateref.current?.focus();
    }
  };
  const handleopeningdateKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      addressline1.current?.focus();
    }
  };
  const handleaddressline1KeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      streetnames.current?.focus();
    }
  };
  const handlestreetnamesKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      Countryref.current?.focus();
    }
  };
  const handleCountryKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      Stateref.current?.focus();
    }
  };
  const handlestateKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      Cityref.current?.focus();
    }
  };
  const handleCityKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      pincoderef.current?.focus();
    }
  };
  const handlepincodeKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      if (
        address1 &&
        street &&
        Branch &&
        selectedState &&
        selectedCountry &&
        selectedCity &&
        Pincode
      ) {
        update();
      } else {
        toast.error("Please fill in required fields");
      }
    }
  };

  const countries = Country.getAllCountries().map((country) => ({
    label: country.name,
    value: country.isoCode,
  }));

  const states = selectedCountry
    ? State.getStatesOfCountry(selectedCountry).map((state) => ({
      label: state.name,
      value: state.isoCode,
    }))
    : [];

  const cities = selectedState
    ? City.getCitiesOfState(selectedCountry, selectedState).map((city) => ({
      label: city.name,
      value: city.name,
    }))
    : [];

  useEffect(() => {
    document.title = "Branches - Settings - iMetanic";
    api.get(`/app/v1/pos/branch`).then((res) => {
      const branches: Branch[] = res.data.result;
      setBranch(branches);
    });
  }, []);

  useEffect(() => {
    if (
      // address1 &&
      // street &&
      Branchname
      // selectedState &&
      // selectedCountry &&
      // selectedCity &&
      // Pincode.length > 6

    ) {
      setvisible(true);
    } else {
      setvisible(false);
    }
  }, [Branch, Branchname]);
  const menu = {
    Edit: "edit",
  };
  const Bbutton = {
    Blue: "b",
    name: "Confirm",
  };

  const update = () => {
    if (
      // selectedState &&
      // address1 &&
      // street &&
      // selectedCountry &&
      // selectedCity &&
      // Pincode &&
      Branchname
    ) {
      const address = {
        Branch_name: Branchname,
        Branch_Opening_date: branchopeningdate,
        Address: { address1, street, selectedCountry, selectedState, selectedCity, Pincode },
      };
      api
        .post(`/app/v1/pos/branch`, address)
        .then((response) => {
          console.log(response.data);
          toast.success("Branch created successfully", {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          const errorMessage = error.response.data.message
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            transition: Flip,
          });
        });
    } else {
      toast.error("Please fill in all required fields.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
    }
  };

  const handleDobChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let newValue = value.replace(/[^0-9-]/g, '');
    const parts = newValue.split('-');
    if (parts.length > 2 && parts[2].length > 4) {
      newValue = `${parts[0]}-${parts[1]}-${parts[2].slice(0, 4)}`;
    }
    if (parts.length === 1) {
      newValue = newValue.length > 2 ? newValue.slice(0, 2) + '-' + newValue.slice(2) : newValue;
    } else if (parts.length === 2) {
      newValue = parts[0] + '-' + (parts[1].length > 2 ? parts[1].slice(0, 2) + '-' + parts[1].slice(2) : parts[1]);
    }
    setInputValue(newValue);
    if (newValue === '') {
      setBranchopeningdate('');
      setdobError(null);
      // setShowFormattedDob(false);
      return;
    }
    const formattedDate = formatDate(newValue);
    if (formattedDate) {
      setBranchopeningdate(formattedDate);
      setdobError(null);
      // setShowFormattedDob(true);
    } else {
      setBranchopeningdate('');
      // setShowFormattedDob(false);
    }
  };
  const formatDate = (dateString: string): string => {
    const parsedDate = parseDate(dateString);
    if (parsedDate) {
      const day = String(parsedDate.getDate()).padStart(2, '0');
      const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
      const year = parsedDate.getFullYear();
      return `${day}-${month}-${year}`;
    }
    return '';
  };
  const parseDate = (dateString: string): Date | null => {
    const numericDateRegex = /^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[0-2])-(\d{4})$/;
    const numericMatch = dateString.match(numericDateRegex);
    if (numericMatch) {
      const [dayStr, monthStr, yearStr] = dateString.split('-');
      const month = parseInt(monthStr, 10);
      const day = parseInt(dayStr, 10);
      const year = parseInt(yearStr, 10);
      if (yearStr.length !== 4 || isNaN(year)) {
        return null;
      }
      if (month < 1 || month > 12) {
        return null;
      }
      if (day < 1 || day > 31 || !isValidDayForMonth(day, month, year)) {
        return null;
      }
      return new Date(year, month - 1, day);
    }
    return null;
  };
  const isValidDayForMonth = (day: number, month: number, year: number): boolean => {
    const daysInMonth = [31, (isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    return day >= 1 && day <= daysInMonth[month - 1];
  };
  const isLeapYear = (year: number): boolean => {
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
  };
  
  // const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setSelectedState(e.target.value);
  //   setSelectedDistrict("");
  // };

  // const handleDistrictChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   setSelectedDistrict(e.target.value);
  // };
  const MButton = {
    Blue: "blue",
    name: "Add Branch",
  };
  const Button = {
    Cancel: "blue",
    name: "cancel",
  };
  const CButton = {
    Blue: "blue",
    name: "Confirm",
  };
  const KButtonerr = {
    error: "blue",
    name: "Confirm",
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      Object.keys(cardRefs.current).forEach((key) => {
        if (
          cardRefs.current[key] &&
          !cardRefs.current[key]!.contains(event.target as Node)
        ) {
          setDropdownVisible((prev) => ({ ...prev, [key]: false }));
        }
      });
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleEdit = (
    id: string, address1: string, street: string, Pincode: string, Branchname: string, branchopeningdate: string, 
    selectedCountry: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined, 
    selectedState: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined, 
    selectedCity: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined) => {
    setId(id);
    setAddress1(address1);
    setStreet(street);
    setPincode(Pincode);
    setBranchname(Branchname);
    setBranchopeningdate(branchopeningdate)
    setedit(true);
  };
  const toggleDropdown = (id: string) => {
    setDropdownVisible((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleDelete = (branchId: string) => {
    setSelectedBranchId(branchId);
    openDeleteModal();
  };

  const confirmDelete = () => {
    api.delete(`/app/v1/pos/branch/${selectedBranchId}`).then((response) => {
      console.log(response);
      toast.success("Branch deleted successfully", { 
        position: "top-right",
        autoClose: 2000,  
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Flip,
      });
      setBranch(
        Branch.filter((branch) => branch._id.$oid !== selectedBranchId)
      );
      closeDeleteModal();
      setTimeout(() => {
        window.location.reload();
      }, 2000); 
    });
  };
  
  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setSelectedBranchId(null);
  };

  const Stateerror = () => {
    setErrorstate(true);
  };

  const handlePincodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let formattedPincode = e.target.value.replace(/\D/g, "");
    formattedPincode = formattedPincode.slice(0, 5);
    setPincode(formattedPincode);

    if (formattedPincode.length < 5) {
      setError("Please enter a valid ZIP or postal code");
    } else {
      setError("");
    }
  };

  const confirm = () => {
    if (visible === true) {
      return (
        <div onClick={update} id="update_btn">
          <Buttons Buttondata={Bbutton} />
        </div>
      );
    } else {
      return (
        <div id="update_btn">
          <Buttons Buttondata={KButtonerr} />
        </div>
      );
    }
  };

  return (
    <div>
      <Header Header="Settings - Branches" />
      <div style={{ display: "flex" }}>
        <Menu />
        <div className="Edit-overall">
          <Editmenu editmenu={menu} />
          <div className="category-add" id="Location_add">
            <div onClick={() => setAddLocation(!Location)}>
              <Buttons Buttondata={MButton} />
            </div>
          </div>
          {Branch && Branch.length === 0 ? (
            <p>No data available</p>
          ) : (<div className="Branch_display">
            {Branch &&
              Branch.map((item) => (
                <Card
                  ref={(el) => (cardRefs.current[item._id.$oid] = el)}
                  className="container_Location"
                  key={item._id.$oid}
                ><div className="text-wrap">
                    <ul>
                      <li>
                        <h4>Branch name</h4>
                        <p>: {item.Branch_name}</p>
                      </li>
                      <li>
                        <h4>Branch opening date</h4>
                        <p>: {formatDate(item.Branch_Opening_date) || 'N/A'}</p>
                      </li>
                      <li>
                        <h4>Contact person name</h4>
                        <p>: {item.contact_person ?? "N/A"}</p>
                      </li>
                      <li>
                        <h4>Contact number</h4>
                        <p>: {item.branch_number ?? "N/A"}</p>
                      </li>
                    </ul>
                    <ol>
                      <h4>Address :</h4>
                      <dl>
                        {(!item.Address.address1 && !item.Address.street && !item.Address.selectedCountry && !item.Address.selectedState && !item.Address.selectedCity && !item.Address.Pincode) ? (
                          <p></p>
                        ) : (
                          <>
                            {item.Address.address1 && item.Address.address1 + ', '}
                            {item.Address.street && item.Address.street + ', '}
                            {item.Address.selectedCountry && item.Address.selectedCountry + ', '}
                            {item.Address.selectedState && item.Address.selectedState + ', '}
                            {item.Address.selectedCity && item.Address.selectedCity + ', '}
                            {item.Address.Pincode}
                          </>
                        )}
                      </dl>
                    </ol>
                  </div>
                  <IconButton
                    aria-label="more"
                    aria-controls={`dropdown-${item._id.$oid}`}
                    aria-haspopup="true"
                    style={{ position: "absolute", top: 0, right: 0 }}
                    onClick={() => toggleDropdown(item._id.$oid)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  {dropdownVisible[item._id.$oid] && (
                    <div
                      className="dropdown-contents"
                      ref={(el) => (cardRefs.current[item._id.$oid] = el)}
                    >
                      <div
                        className="edit"
                        onClick={() => {
                          handleEdit(
                            item._id.$oid,
                            item.Address.address1,
                            item.Address.street,
                            item.Address.Pincode,
                            item.Branch_name,
                            item.Branch_Opening_date,
                            item.Address.selectedCountry,
                            item.Address.selectedState,
                            item.Address.selectedCity 
                          );
                          setDropdownVisible((prev) => ({
                            ...prev,
                            [item._id.$oid]: false,
                          }));
                        }}
                      >
                        Edit
                      </div>
                      <div
                        className="delete"
                        onClick={() => {
                          handleDelete(item._id.$oid);
                          setDropdownVisible((prev) => ({
                            ...prev,
                            [item._id.$oid]: false,
                          }));
                        }}
                      >
                        Delete
                      </div>
                    </div>
                  )}
                </Card>
              ))}
          </div>)}
          <Dialog open={deleteModalOpen} onClose={closeDeleteModal}>
            <DialogTitle>
              <h3 style={{ marginLeft: "85px" }}>Delete branch</h3>
            </DialogTitle>
            <DialogContent>
              <p>Are you sure you want to delete this branch?</p>
            </DialogContent>
            <DialogActions>
              <div id="cancel_btn" onClick={() => window.location.reload()}>
                <Buttons Buttondata={Button} />
              </div>
              <div onClick={confirmDelete}>
                <Buttons Buttondata={CButton} />
              </div>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      {Location && (
        <div className="modals" id="modals">
          <div className="overlays" id="overlays"></div>
          <div className="modal-contents " id="modal_contents">
            <div className="location-popupscreen" id="location_popupscreen">
              <button
                onClick={() => window.location.reload()}
                className="cancel-icon-Branch"
              >
                <CloseIcon />
              </button>
              <h2 style={{ marginLeft: "200px", marginBottom: "10px" }}>
                Add branch
              </h2>
              <div className="user_input" id="user_input">
                <div className="Address">
                  <TextField
                    className="Inputuser"
                    id="Branch"
                    fullWidth
                    label={
                      <span>
                        Branch name <span style={{ color: '#f04141', fontSize: "15px" }}>*</span>
                      </span>
                    }
                    variant="outlined"
                    value={Branchname}
                    onChange={(e) => setBranchname(e.target.value)}
                    onKeyPress={handleBranchnamesKeyPress}
                    style={{ marginBottom: errors ? "1rem" : 0 }}
                  />
                  <TextField
                    error={!!doberror}
                    className="Inputuser"
                    id="opening_date"
                    type="text"
                    fullWidth
                    label={
                      <span>
                        Branch opening date (dd-mm-yyyy)
                      </span>
                    }
                    variant="outlined"
                    // onChange={handleChange}
                    onChange={handleDobChange}
                    value={inputValue || ''}
                    helperText={doberror ? 'Invalid date format, Please enter in dd-mm-yyyy format.' : ''}
                    onKeyPress={handleopeningdateKeyPress}
                    inputRef={branchopeningdateref}
                    InputLabelProps={{ style: { backgroundColor: '#f1f1f1' } }}
                    sx={{ marginBottom: doberror ? 2 : 1 }}
                  />
                  <TextField
                    className="Inputuser"
                    id="address"
                    fullWidth
                    label={
                      <span>
                        Flat /House no. /Building /Company /Apartment
                      </span>
                    }
                    variant="outlined"
                    value={address1}
                    onChange={(e) => setAddress1(e.target.value)}
                    onKeyPress={handleaddressline1KeyPress}
                    inputRef={addressline1}
                  />
                  <TextField
                    className="Inputuser"
                    id="street"
                    fullWidth
                    label={
                      <span>
                        Area /Street /Sector /Town
                      </span>
                    }
                    variant="outlined"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    onKeyPress={handlestreetnamesKeyPress}
                    inputRef={streetnames}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label={
                          <span>
                            Country
                          </span>
                        }
                        select
                        fullWidth
                        value={selectedCountry}
                        onChange={(e) => {
                          setSelectedCountry(e.target.value);
                          setSelectedState('');
                          setSelectedCity('');
                        }}
                        onKeyPress={handleCountryKeyPress}
                        inputRef={Countryref}
                      >
                        {countries.map((country) => (
                          <MenuItem key={country.value} value={country.value}>
                            {country.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label={
                          <span>
                            State
                          </span>
                        }
                        select
                        fullWidth
                        value={selectedState}
                        onChange={(e) => {
                          setSelectedState(e.target.value);
                          setSelectedCity('');
                        }}
                        onKeyPress={handlestateKeyPress}
                        inputRef={Stateref}
                        disabled={!selectedCountry}
                      >
                        {states.map((state) => (
                          <MenuItem key={state.value} value={state.value}>
                            {state.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        label={
                          <span>
                            City
                          </span>
                        }
                        select
                        fullWidth
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        onKeyPress={handleCityKeyPress}
                        inputRef={Cityref}
                        disabled={!selectedState}
                      >
                        {cities.map((city) => (
                          <MenuItem key={city.value} value={city.value}>
                            {city.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  {/* <div style={{ display: "flex", width: "540px" }}>
                    <NativeSelect
                      style={{ width: "250px", height: "40px" }}
                      value={selectedState}
                      onChange={handleStateChange}
                      onKeyPress={handleselectstateKeyPress}
                      inputRef={selectstateref}
                    >
                      <option value="">Select State</option>
                      {Object.keys(statesAndDistricts).map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </NativeSelect>
                    {selectedState ? (
                      <div>
                        <NativeSelect
                          style={{
                            height: "40px",
                            width: "272px",
                            marginLeft: "10px",
                          }}
                          value={selectedDistrict}
                          onChange={handleDistrictChange}
                          onKeyPress={handleselectdistrictKeyPress}
                          inputRef={selectdistrictref}
                        >
                          <option value="">Select District</option>
                          {statesAndDistricts[selectedState].map((district) => (
                            <option key={district} value={district}>
                              {district}
                            </option>
                          ))}
                        </NativeSelect>
                      </div>
                    ) : (
                      <div>
                        <NativeSelect
                          style={{
                            height: "40px",
                            width: "277px",
                            marginLeft: "12px",
                          }}
                          value={selectedDistrict}
                          onClick={Stateerror}
                        >
                          <option value="">Select District</option>
                        </NativeSelect>
                        {stateerror ? (
                          <p style={{ color: "red" }}>Please fill the STATE</p>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div> */}
                  <TextField
                    className="Inputuser"
                    id="pincode"
                    fullWidth
                    label={
                      <span>
                        Pincode/Zipcode
                      </span>
                    }
                    margin="normal"
                    type="text"
                    error={!!error}
                    helperText={error}
                    inputProps={{ maxLength: 7 }}
                    value={Pincode}
                    onChange={handlePincodeChange}
                    onKeyPress={handlepincodeKeyPress}
                    inputRef={pincoderef}
                  />
                </div>
              </div>
              <div className="conform-btn-location" id="btn_confirm_edit">
                <div
                  id="cancel_btn"
                  onClick={() => window.location.reload()}
                  style={{ marginLeft: "50px" }}
                >
                  <Buttons Buttondata={Button} />
                </div>
                {confirm()}
                
              </div>
            </div>
          </div>
        </div>
      )}
      {edit && <EditBranch id={Id} />}
      <ToastContainer />
    </div>
  );
}

export default Edit;
