import React, { useEffect, useState } from "react";
import "./Orderstatus.css";
import Popupbill from "./Popupbill";
import { Button } from "@mui/material";
import api from "../../axiosConfig";
interface Getdata {
  _id: {
    $oid: string;
  };
  Products: Array<{
    Name: string;
    productId: number;
    phone_number: string;
    Address: string;
    Email: string;
    products: Array<{
      productName: string;
      amount: string;
      quantity: string;
      totalPrice: string;
    }>;
    totalAmount: string;
    totalQuantity: number;
    vat: string;
  }>;
  Orderdate: string;
  Ordertime: string;
  ReceivedAmount: string;
  RemainingAmount: string;
  Paymentmode: string;
  OrderStatus: string;
  DeliveryDate: string;
  Orderid: number;
  createdBy: {
    user_name: string;
    name: string;
    organization_name: string;
    address: string;
    phone_number: number;
  };
}
function OrderStatus() {
  const [, setGetdata] = useState<Getdata[]>([]);
  const [confirm, setConfirm] = useState<Getdata[]>();
  const [inprocess, setInprocess] = useState<Getdata[]>();
  const [wfd, setWfd] = useState<Getdata[]>();
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [modal, setModal] = useState(false);
  const [orderid, setId] = useState<any>();
  useEffect(() => {
    api
      .get(`/app/v1/pos/order`)
      .then((res) => {
        console.log(res);
        const getdata: Getdata[] = res.data.result;
        setGetdata(getdata);
        const confirms = getdata.filter((item) => {
          return item.OrderStatus === "Confirm";
        });
        setConfirm(confirms);
        const inproces = getdata.filter((item) => {
          return item.OrderStatus === "In-Process";
        });
        setInprocess(inproces);
        const wfds = getdata.filter((item) => {
          return item.OrderStatus === "Waiting-for-delivery";
        });
        setWfd(wfds);
      })
      .catch((res) => {
        console.log(res);
      });
  }, []);
  const handleChange = (
    id: string,
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newStatus = event.target.value;
    setSelectedOption(newStatus);
    const data = {
      OrderStatus: newStatus,
    };
    api
      .put(`/app/v1/pos/order/${id}`, data)
      .then((res) => {
        console.log(res);
        window.location.reload();
      })
      .catch((res) => {
        console.log(res);
      });
  };
  const openpage = (id: string) => {
    setModal(!modal);
    setId(id);
  };

  return (
    <div style={{ display: "flex" }}>
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h1>Working Orders</h1>
          <a href="/" style={{ marginLeft: "20px" }}>
            <p>Create New Order</p>
          </a>
        </div>
        <div className="Order-View">
          <div className="sub-view">
            <div>
              <h1>Confirm</h1>
            </div>
            <div className="overflow-page">
              <div>
                {confirm?.map((item) =>
                  item.Products.map((order) => (
                    <div key={item.Orderid} className="order-icons">
                      <div>
                        <div
                          style={{ width: "290px" }}
                          onClick={() => openpage(item._id.$oid)}
                        >
                          Order id: {item.Orderid}
                          <br />
                          Delivery date: {item.DeliveryDate}
                          <br />
                          No.of Quantity: {order.totalQuantity}
                        </div>
                        <div>
                          Status:
                          <select
                            value={selectedOption}
                            onChange={(e) => handleChange(item._id.$oid, e)}
                          >
                            <option value="">Confirm</option>
                            <option value={"In-Process"}>In-Process</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
          <div className="sub-view" style={{ backgroundColor: "#C9EE40" }}>
            <div>
              <h1>In-Process</h1>
            </div>
            <div className="overflow-page">
              <div>
                {inprocess?.map((item) =>
                  item.Products.map((order) => (
                    <div key={item.Orderid} className="order-icons">
                      <div>
                        <div
                          style={{ width: "290px" }}
                          onClick={() => openpage(item._id.$oid)}
                        >
                          Order id: {item.Orderid}
                          <br />
                          Delivery date: {item.DeliveryDate}
                          <br />
                          No.of quentity: {order.totalQuantity}
                        </div>
                        <div>
                          Status:
                          <select
                            value={selectedOption}
                            onChange={(e) => handleChange(item._id.$oid, e)}
                          >
                            <option value="">In-Process</option>
                            <option value={"Waiting-for-delivery"}>
                              Waiting-for-delivery
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
          <div className="sub-view" style={{ backgroundColor: "#2EF035" }}>
            <div>
              <h1>Waiting for delivery</h1>
            </div>
            <div className="overflow-page">
              <div>
                {wfd?.map((item) =>
                  item.Products.map((order) => (
                    <div key={item.Orderid} className="order-icons">
                      <div>
                        <div
                          style={{ width: "290px" }}
                          onClick={() => openpage(item._id.$oid)}
                        >
                          Order id: {item.Orderid}
                          <br />
                          Delivery date: {item.DeliveryDate}
                          <br />
                          No.of Quantity: {order.totalQuantity}
                          <br />
                        </div>
                        <div>
                          Status:
                          <select
                            value={selectedOption}
                            onChange={(e) => handleChange(item._id.$oid, e)}
                          >
                            <option value="">Waiting-for-delivery</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
        {modal && (
          <div className="modal">
            <div className="overlay">
              <div className="modal-content">
                <div
                  style={{
                    width: "500px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <h3 style={{ marginRight: "80px" }}>
                    <b>Order Details</b>
                  </h3>
                  <Button
                    style={{
                      color: "red ",
                      marginTop: "-40px",
                      marginRight: "-20px",
                    }}
                    onClick={() => setModal(!modal)}
                  >
                    <h2>x</h2>
                  </Button>
                </div>
                <Popupbill id={orderid} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OrderStatus;
