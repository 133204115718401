import React, { useEffect, useState } from "react";
import "./cart.css";
import Tables from "../../Common_compound/Table";
import Loadingscr from "../../Loading/Loadingscr";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { useNavigate } from "react-router-dom";
import api from "../../axiosConfig";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { IconButton, Table, TableBody, TableCell, TableRow, Tooltip } from "@mui/material";
interface Getdata {
  _id: {
    $oid: string;
  };
  product: Array<{
    productid: string;
    productName: string;
    amount: string;
    quantity: string;
    totalprice: string;
  }>;
  totalAmount: string;
  vat: string;
  phone_number: string;
  Email: string;
  address: string;
  totalQuantity: string;
  cartId: string;
  Name: string;
}

function Cart() {
  const [Loading, setLoading] = useState<boolean>(false);
  const [getdata, setGetdata] = useState<Getdata[]>([]);
  const navigation = useNavigate();
  useEffect(() => {
    document.title = "Shopping Cart - iMetanic";
  });
  useEffect(() => {
    setLoading(true);
    api.get(`/app/v1/pos/shopping_cart`).then((res) => {
      const get: Getdata[] = res.data.result;
      setGetdata(get);
    });
    setLoading(false);
  }, []);

  const roundValue = (value: number | undefined) => {
    if (value === undefined) return 0;
    const num = Number(value);
    const thirdDecimal = Math.floor(num * 1000) % 10;
    return thirdDecimal >= 5
      ? (Math.ceil(num * 100) / 100).toFixed(2)
      : num.toFixed(2);
  };
  const Products = {
    titlesno: "S.No",
    titlename1: "Cart ID",
    titlename2: "Customer name",
    titlename3: "Product total quantity",
    titlename4: "Total Price",
    titlename5: "Actions",
    S_no: getdata.map((item, index) => index + 1),
    bodyname1: getdata.map((item) => item.cartId),
    bodyname2: getdata.map((item) => item.Name),
    bodyname3: getdata.map((item) => item.totalQuantity),
    bodyname4: getdata.map((item) => roundValue(item.totalAmount ? parseFloat(item.totalAmount) : undefined)),
    bodyname5: getdata.map((item) => (
      <button className="icon-btn-edit" onClick={() => makeorder(item._id.$oid)}>
        <Tooltip title="Make to order" placement="right" arrow>
                <IconButton>
        <ShoppingCartCheckoutIcon />
        </IconButton>
        </Tooltip>
      </button>
    )),
  };
  const makeorder = (cartid: any) => {
    navigation(`/v1/Cart/${cartid}`);
  };
  return (
    <div>
      <Header Header="Shopping Cart" />
      <div style={{display:'flex'}}>
        <Menu />
        {getdata.length === 0 ? (
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={5} // Adjust to the number of columns you have
                  align="center"
                  sx={{ color: "black", fontSize: "large" }}
                >
                 No data available
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <div className="Overall_cart">
            <Tables product={Products} />
            {Loading ? <Loadingscr /> : null}
          </div>
        )}
      </div>
    </div>
  );
}

export default Cart;
