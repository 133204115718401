import React, { useEffect } from "react";
import "./Dashboard.css";
import Oredrhistory from "./Oredrhistory";
import Barchart from "./Barchart";
import Header from "../../components/Header";
import Menu from "../../components/Menu";

function Dashboard() {
  useEffect(() => {
    document.title = "Dashboard - iMetanic";
  });
  return (
    <div>
      <Header Header="Dashboard" />
      <div style={{ display: 'flex',}}>
        <Menu />
        <div className="overalls">
          <div style={{ marginLeft: "20px", marginTop: '30px'}}>
            <Barchart />
            <Oredrhistory />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
