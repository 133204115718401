import React, { useEffect, useState, useRef } from 'react';
import './Print.css';
import api from '../../axiosConfig';
import { useParams } from 'react-router-dom';
import printJS from 'print-js';
import Loadingscr from '../../Loading/Loadingscr';

interface Getdata {
  Customerid: any;
  _id: {
    $oid: string;
  };
  Products: Array<{
    productName: string;
    arabicword:string;
    amount: number;
    quantity: number;
    totalPrice: number;

  }>;
  Orderdate: string;
  Ordertime: string;
  ReceivedAmount: number;
  RemainingAmount: number;
  Paymentmode: string;
  OrderStatus: string;
  discount: number;
  Finalamount: number;
  totalAmount: number;
  totalQuantity: number;
  Email: string;
  CustomerName: string;
  CustomerPhoneNo: number;
  Orderid: number;
}
interface Customerdata {
  customer: {
    _id: {
      $oid: any;
    }
    Email: string;
    Name: string;
    phone_number: string;
    Address: {
      customeraddress1: string;
      customeraddress2: string;
      customeraddress3: string;
    }
  }
  ledger: Array<{
    OrderDetails: Array<{
      Orderamount: string;
      Orderdate: string;
      ReceivedAmount: string;
      RemainingAmount: string;
      Total_price: string;
      Totalamount: string;
      orderid: string;
      discount: string;
      Ordertime: string;
    }>
    customer_id: string;
    customer_name: string;
    customer_phone_number: string;
    new_order_amount: string;
    total_outstanding_balance: string;
    outstanding_balance: string;
    pay_amount: string;
  }>
}
const Print: React.FC = () => {
  const { orderid } = useParams<{ orderid: string }>();
  const [getorder, setGetOrder] = useState<Getdata>();
  const [businessName, setBusinessName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [time, setTime] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [Loading, setLoading] = useState<boolean>(false)
  const hasPrinted = useRef(false);
  const [outstandingAmount, setOutstandingAmount] = useState<number>(0);
  const prints = useRef(false);
  // eslint-disable-next-line no-empty-pattern
  const [] = useState<Customerdata>();

  useEffect(() => {
    if(!prints.current){
      date_time();
      fetchData();
      prints.current=true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchData = async () => {
    try {
      setLoading(true)
      const busnessName = sessionStorage.getItem('organization_name') || '';
      setBusinessName(busnessName);
      const phoneNum = sessionStorage.getItem('business_phone') || '';
      setPhoneNumber(phoneNum);

      const orderResponse = await api.get(`/app/v1/pos/order/${orderid}`);
      const orderData: Getdata = orderResponse.data.result;
      setGetOrder(orderData);
      setLoading(false)

      if (orderData?.Customerid) {
        const ledgerResponse = await api.get(`/pos/ledger/${orderData.Customerid}`);
        const totalOutstanding = ledgerResponse.data.ledger.reduce(
          (sum: number, item: { total_outstanding_balance: string }) => sum + parseFloat(item.total_outstanding_balance || '0'), 0);
        setOutstandingAmount(totalOutstanding);
      }
      setLoading(false);

      if (!hasPrinted.current) {
        // printReceipt();
        setTimeout(() => {
          window.print();
          // hasPrinted.current = true;
          handleBack();
        }, 1000);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  // const printReceipt = () => {
  //   setTimeout(() => {
  //     printJS({
  //       printable: 'printableArea',
  //       type: 'html',
  //       targetStyles: ['*'],
  //       showModal: false, 
  //       onPrintDialogClose: handleBack, 
  //     });
  //   }, 1600);
  // };

  const roundValue = (value: number | undefined) => {
    if (value === undefined) return 0;
    const num = Number(value);
    const thirdDecimal = Math.floor(num * 1000) % 10;
    return thirdDecimal >= 5
      ? (Math.ceil(num * 100) / 100).toFixed(2)
      : num.toFixed(2);
  };

  const handleBack = () => {
    setTimeout(() => {
      window.history.back();
    }, 1700);
  };

  const date_time = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    setDate(`${day}/${month}/${year}`);
    setTime(`${hours}:${minutes}:${seconds}`);
  };

  return (
    <div id="printableArea" className="Print_popup">
      <header>
        <div style={{display:"grid",alignItems:"center",justifyContent:"center"}}>
        <h2 style={{width:"250px",display:"flex",alignItems:"center",justifyContent:"center"}}>{businessName}</h2>
        <p style={{display:"flex",alignItems:"center",justifyContent:"center"}}>{phoneNumber}</p>
        </div>
      </header>
      <ul>
        <li style={{height:"35px",marginTop:"2px"}}>
          <p>Order ID: {getorder?.Orderid}</p>
          <p>{date} | {time}</p>
        </li>
        <li style={{height:"45px",justifyContent:"space-evenly",marginTop:"2px"}}>
          <h5>Customer Details:</h5>
          <p style={{ marginLeft: '20px' }}>{getorder?.CustomerName}</p>
          <p style={{ marginLeft: '20px',position:"relative" }}>{getorder?.CustomerPhoneNo}</p>
        </li>
      </ul>
      <div className='product_details_print'>
        {getorder?.Products.map(pro => (
          <React.Fragment key={pro.productName}>
            <ol style={{padding:"3px"}}>
              <dl style={{display:"flex",width:"150px" }}>
                {pro.productName} | {pro.arabicword}
                <br />
                {pro.quantity} x {roundValue(pro.amount)} ر.ق
              </dl>
              <dl className='product-total-amount'>
                {roundValue(pro.totalPrice)} ر.ق</dl>
            </ol>
          </React.Fragment>
        ))
        }
      </div>
      <div className="Print_data">
        <ol>
          <div style={{height:"30px",display:"grid",justifyContent:"space-evenly",marginTop:"5px"}}>
            <p>Total Item: {getorder?.Products.length}</p>
            <p style={{ width: "220px" }}>Total Quantity: {getorder?.totalQuantity}</p>
          </div>
        </ol>
        <ol style={{height:"30px"}} >
          <h3 style={{width:"100px"}}>Total (المجموع)</h3>
          <div style={{display:"flex",flexDirection:"row-reverse",marginRight: '2px'}}>
          <h3 style={{ }}>{roundValue(getorder?.totalAmount)} ر.ق</h3>
          </div>
        </ol>

        <ol style={{ width: "220px" ,justifyContent:"space-between",height:"35px",marginBottom:"3px"}}>
          <li>
            <p>Paid </p>
            {/* <br/> */}
            (مدفوع)
            {/* <br /> */}
            {/* <p style={{position:"relative",top:"15px"}}>{roundValue(typeof getorder?.ReceivedAmount === 'number' ? getorder.ReceivedAmount : undefined)}ر.ق</p> */}
          </li>
          <li>
            <p>Unpaid</p>
            {/* <br/> */}
            (غير مدفوع)
            {/* <br /> */}
            {/* <p>{roundValue(typeof getorder?.RemainingAmount === 'number' ? getorder.RemainingAmount : undefined)}ر.ق</p> */}
          </li>
          <li>
            <p>Amount Due</p>
            {/* <br/> */}
            (لمبلغ المتبقي)
            {/* <br /> */}
            {/* <p>{roundValue(typeof getorder?.RemainingAmount === 'number' ? getorder.RemainingAmount : undefined)}ر.ق</p> */}
          </li>
        </ol>
        <div style={{display:"flex",justifyContent:"space-between",}}>
        <p >{roundValue(typeof getorder?.ReceivedAmount === 'number' ? getorder.ReceivedAmount : undefined)}ر.ق</p>
        <p>{roundValue(typeof getorder?.RemainingAmount === 'number' ? getorder.RemainingAmount : undefined)}ر.ق</p>
        <p>{roundValue(typeof getorder?.RemainingAmount === 'number' ? getorder.RemainingAmount : undefined)}ر.ق</p>
        
        </div>
        <ol>
          <li style={{ marginBottom: '20px',display:"flex",}}>
            <h3 style={{ width: "150px" ,marginTop:"7px"}}>Outstanding (الباقي)</h3>
          </li>
          <li style={{ marginBottom: '20px',display:"flex",flexDirection:"row-reverse"}}>
             <h3 style={{marginTop:"7px"}}>{roundValue(outstandingAmount)}ر.ق</h3>
            {/* <h3 style={{ marginRight: '10px' }}>{customer?.Out_standing_balance}</h3> */}
          </li>
        </ol>
      </div>
      <p style={{ marginLeft: "50px", width: "160px"}}>Thank you for the visit</p>
      <p style={{ fontSize: 'small', marginBottom: '15px', marginLeft: "60px", width: "190px" }}>Powered by iMetanic</p>
      <ol></ol>
      <ul>
      </ul>
      {Loading ? <Loadingscr /> : ''}
    </div>
  );
};

export default Print;
