import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/components/Login";
import Signup from "./pages/components/Signup";
import Home from "./pages/components/Home";
import Cart from "./pages/Sub page/Cartdisplay/Cart";
import Editpage from "./pages/Sub page/Edit/Edit";
import Dashboard from "./pages/Sub page/Dashboard/Dashboard";
import OrderSearch from "./pages/Sub page/Orders/OrderSearch";
import Profile from "./pages/Sub page/Profile/Profile";
import Print from "./pages/Sub page/Bom/Print";
import OrderStatus from "./pages/Sub page/OrderStatus/OrderStatus";
import OrderDisplay from "./pages/Sub page/Orders/OrderDisplay";
import Addagent from "./pages/Sub page/Edit/Addagent";
import Addproduct from "./pages/Sub page/Edit/Addproduct";
import Addcategory from "./pages/Sub page/Edit/Addcategory";
import Chartdata from "./pages/Sub page/Cartdisplay/Cartdata";
import Billofmaterial from "./pages/Common_compound/Bill-of-material";
import Setpassword from "./pages/components/Setpassword";
import Notfound from "./pages/components/Not_found";
import Customerdata from "./pages/Sub page/Edit/Customerdata";
import MasterHome from "./pages/components/Master/MasterHome";
import Ledger from "./pages/Sub page/Ledger/Ledger";
import Ledgerdata from "./pages/Sub page/Ledger/ledgerdata";
import Stock from "./pages/Sub page/Stock/Stock";
import ForgotPassword from "./pages/components/Forgotpassword";
import Resetpassword from "./pages/components/Resetpassword";
import Demopdf from "./pages/Model/Demopdf";
import Demosheet from "./pages/Sub page/Orders/Demosheet";

const isTokenValid = (): boolean => {
  const token = sessionStorage.getItem("jsonwebtoken");
  return !!token;
};
const busness_name = (): boolean => {
  const businessName = (sessionStorage.getItem("organization_name")) === "iMetanic";
  return !!businessName;
}
const ProtectedRoutesemployee: React.FC = () => {
  const tokenValid = isTokenValid();
  // const businessName = busness_name()
  if (!tokenValid) {
    return <Navigate to="/" />;
  } else {
    return (
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/Cart" element={<Cart />} />
        <Route path="/Cart/:cartid" element={<Chartdata />} />
        <Route path="/Order" element={<OrderSearch />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/Print/:orderid" element={<Print />} />
        <Route path="/Orderstatus" element={<OrderStatus />} />
        <Route path="/OrderDetails/:orderid" element={<OrderDisplay />} />
        <Route path="/Ledger" element={<Ledger />} />
        <Route path="/Ledger/:CustomerId" element={<Ledgerdata />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    )
  }
}
const ProtectedRoutes: React.FC = () => {
  const tokenValid = isTokenValid();
  const businessName = busness_name()
  if (!tokenValid) {
    return <Navigate to="/" />;
  } else {
    if (businessName) {
      return <Routes>
        <Route path="/home" element={<MasterHome />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    }
    else {
      return (
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/Cart" element={<Cart />} />
          <Route path="/pdf" element={<Demopdf />} />
          <Route path="demosheet" element={<Demosheet />} />
          <Route path="/Cart/:cartid" element={<Chartdata />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/Order" element={<OrderSearch />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/Print/:orderid" element={<Print />} />
          <Route path="/Orderstatus" element={<OrderStatus />} />
          <Route path="/OrderDetails/:orderid" element={<OrderDisplay />} />
          <Route path="/Billofmaterial" element={<Billofmaterial />} />
          <Route path="/Edit/*" element={<SettingRoutes />} />
          <Route path="/Ledger" element={<Ledger />} />
          <Route path="/Ledger/:CustomerId" element={<Ledgerdata />} />
          <Route path="/Stock" element={<Stock />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      );
    }
  }
};
const SettingRoutes: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/Location" element={<Editpage />} />
        <Route path="/addagent" element={<Addagent />} />
        <Route path="/addproduct" element={<Addproduct />} />
        <Route path="/addcategory" element={<Addcategory />} />
        <Route path="/Customerdata" element={<Customerdata />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </>
  );
};
const App: React.FC = () => {
  const [employee, setemployee] = useState<boolean>(false)
  useEffect(() => {
    const check_on = () => {
      const employee_login = sessionStorage.getItem('employee')
      const employee_data = sessionStorage.getItem('Noemployee')
      if (employee_login) {
        setemployee(true)
      } else if(employee_data) {
        setemployee(false)
      }
    }
    check_on() 
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/imetanic/setpassword/:userid/:expiryTime/2580" element={<Setpassword />} />
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<Resetpassword />} />
        <Route path='/v1/employee/*' element={<ProtectedRoutesemployee />} /> 
         <Route path="/v1/*" element={<ProtectedRoutes />} />
        <Route path="*" element={<Notfound />} />
        <Route path="/MasterHome" element={<MasterHome />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
