import React, { useEffect, useState } from "react";
import payimg from "../../assets/Images/pay.png";
import money from "../../assets/Images/1635d86b32b60583e7f022b9c3324b3d.png";
import "react-toastify/dist/ReactToastify.css";
import Done from "./Done";
import { Button, Checkbox, Input, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Payment from "./Payment";
import Buttons from "../Common_compound/Buttons";
import api from "../axiosConfig";
import CloseIcon from '@mui/icons-material/Close';
interface Props {
  cartId: string | null;
  backHome: any;
  backHomepay: any;
}
interface Product {
  _id: {
    $oid: string;
  };
  Finalamount: number;
  Customerid: string;
  products: Array<{
    amount: string;
    productId: string;
    discount: number;
    productName: string;
    quantity: string;
    totalPrice: string;
  }>;
  totalAmount: string;
  discount: string;
  vat: string;

}
interface Customerdata {
  customer: {
    _id: {
      $oid: any;
    }
    Email: string;
    Name: string;
    phone_number: string;
    Address: {
      customeraddress1: string;
      customeraddress2: string;
      customeraddress3: string;
    }
  }
  ledger: Array<{
    OrderDetails: Array<{
      Orderamount: string;
      Orderdate: string;
      ReceivedAmount: string;
      RemainingAmount: string;
      Totalamount: string;
      orderid: string;
    }>
    customer_id: string;
    customer_name: string;
    customer_phone_number: string;
    new_order_amount: string;
    total_outstanding_balance: string;
    outstanding_balance: string;
    pay_amount: string;
  }>
}
const Paycash: React.FC<Props> = ({ cartId, backHome, backHomepay }) => {
  const [bproducts, setBproducts] = useState<Product[]>([]);
  const [receivedAmount, setReceivedAmount] = useState<any>();
  const [remainingAmount, setRemainingAmount] = useState<number>(0);
  const [orderid, setOrderId] = useState<string>("");
  const [backpage, setBackpage] = useState<boolean>(false);
  const [customer, setCustomer] = useState<Customerdata | null>(null);
  const [customerdata, setCustomerdata] = useState<string>('');
  const [discount, setdiscount] = useState<number>()
  const [DeliveryDate, setDeliveryDate] = useState<string>(() => {
    const currentDate = new Date();
    const futureDate = new Date(currentDate);
    futureDate.setDate(currentDate.getDate() + 5);
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = futureDate.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  });
  const [time, setTime] = useState({
    hours: "",
    minutes: "",
    seconds: "",
    day: "",
    month: "",
    year: ""
  })
  const [paymentsuccess, setPaymentsuccess] = useState<boolean>(false);
  const [showBalance, setShowBalance] = useState<boolean>(false);
  useEffect(() => {
    setRemainingAmount(
      bproducts.reduce((acc, item) => acc + Number(item.Finalamount), 0) - receivedAmount
    );
  }, [receivedAmount, bproducts]);
  useEffect(() => {
    date()
    setReceivedAmount(0)
    const apigot = () => {
      api
        .get(`/app/v1/pos/shopping_cart/${cartId}`)
        .then((res) => {
          const categoryData: Product[] = res.data.result;
          setBproducts(categoryData);
          const dis = categoryData.map(item => item.discount)
          setdiscount(Number(dis));
          const customerid = String(categoryData.map(item => item.Customerid))
          setCustomerdata(customerid)
          api.get(`pos/ledger/${customerid}`)
            .then((response) =>
              setCustomer(response.data)
            ).catch((error) => {
              console.log(error);
            })
        })
        .catch((err) => {
          console.log(err);
        });
    }
    apigot()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const osb = () => {
    const cus = customer?.ledger
    console.log('cus', cus);

    if (cus !== undefined && cus.length > 0) {
      return (cus?.map(i => i.total_outstanding_balance))
    } else {
      return (0)
    }
  }
  const osbs = () => {
    const cus = customer?.ledger
    console.log('cus', cus);

    if (cus !== undefined && cus.length > 0) {
      return (cus?.map(i => i.total_outstanding_balance)[0])
    } else {
      return (0)
    }
  }
  const clickdata = () => {
    setReceivedAmount(0)
    setShowBalance(!showBalance)
  }
  const ordersccuess = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const newOrderId = `${month}${day}${hours}${minutes}${seconds}`;
    setOrderId(newOrderId);
    const order = {
      discount: discount,
      Finalamount: Number(bproducts.map((item) => item.totalAmount),) - Number(discount),
      totalAmount: bproducts.map((item) => item.totalAmount)[0],
      Customerid: bproducts.map((item) => item.Customerid)[0],
      Products: bproducts.map((item) => item.products)[0],
      ReceivedAmount: receivedAmount,
      RemainingAmount: remainingAmount,
      Orderdate: `${time.year}-${time.month}-${time.day}`,
      Ordertime: `${time.hours}:${time.minutes}:${time.seconds}`,
      Paymentmode: "Cash",
      OrderStatus: "Confirm",
      Orderid: `${month}${day}${hours}${minutes}${seconds}`,
    };
    console.log('order', order);
    api.post(`/app/v1/pos/order`, order)
      .then((res) => {
        console.log('res', res);
        const id = res.data.Id;
        setOrderId(order.Orderid);
        
        if (id) {
          const customerUpdate = {
            id: order.Customerid,
            order_oid: id,
            outstanding_balance: osbs(),
            new_order_amount: order.totalAmount,
            pay_amount: order.ReceivedAmount,
            order_id: order.Orderid,
            discount: order.discount,
            Orderdate: `${time.year}-${time.month}-${time.day}`,
            Ordertime: `${time.hours}:${time.minutes}`,
          };
          api.post(`pos/ledger`, customerUpdate)
            .then((response) => {
              console.log(response);
                api.delete(`/app/v1/pos/shopping_cart/${cartId}`).then((res) => {
                  console.log(res);
                  setPaymentsuccess(!paymentsuccess);
                });
            }).catch((error)=>{
              console.log(error);
            })
        }
      })
      .catch((error) => {
        console.error("Error saving cart:", error);
      });
  };
  const BButton = {
    Blue: "blue",
    name: " COMPLETE",
  };
  const CButton = {
    Cancel: "red",
    name: "CANCEL",
  };
  const MButton = {
    Blue: "blue",
    name: " Add product",
  };
  const finalamount = () => {
    if (showBalance === true) {
      const getamount = customer?.ledger.map(i => i.total_outstanding_balance);
      const orderamount = bproducts.map(p => p.Finalamount)
      const amount_in = Number(getamount) + Number(orderamount)
      return (amount_in)
    } else {
      const orderamount = bproducts.map(p => p.Finalamount)
      return (orderamount)
    }
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inamount = Number(finalamount());
    let term = e.target.value;

    if (term.startsWith("0") && term.length > 1 && !term.startsWith("0.")) {
      term = term.replace(/^0+/, "");
      e.target.value = term;
    }

    if (term === "") {
      setReceivedAmount(0);
      return;
    }

    const parsedValue = parseFloat(term);
    if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= inamount) {
      setReceivedAmount(parsedValue);
    }
  };

  const roundAmount = (value: any) => {
    const num = Number(value);
    if (isNaN(num)) {
      return '0.00';
    }
    const rounded = Math.floor(num * 100) / 100;
    const thirdDigit = Math.floor((num * 1000) % 10);
    return thirdDigit > 5 ? (rounded + 0.01).toFixed(2) : rounded.toFixed(2);
  };

  const date = () => {
    const currentDate = new Date();
    const year = String(currentDate.getFullYear());
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    setTime({
      hours: hours,
      minutes: minutes,
      seconds: seconds,
      day: day,
      month: month,
      year: year,
    })
  }

  return (
    <div>
      {backpage ? (
        <Payment cartId={cartId} backHomepay={backHomepay} backHome={backHome} />
      ) : (
        <div>
          {paymentsuccess ? (
            <Done orderid={orderid} />
          ) : (
            <div className="Popup">
              {/* <Tooltip title="Add product" placement="bottom" arrow>
                  <button
                    onClick={() => backHome('false')}
                    // className="backhome-btn"
                    style={{ border: "none", marginLeft: "30px" }}
                  >
                    <ControlPointDuplicateIcon style={{ cursor: "pointer", border: "none" }} />
                  </button>
                </Tooltip> */}
              <Button onClick={() => setBackpage(!backpage)} >
                <CloseIcon style={{ position: "relative", left: "230px", bottom: "10px", color: "black" }} />
              </Button>

              <Button onClick={() => setBackpage(!backpage)} className="atom">
                <ArrowBackIcon style={{ color: "black", marginTop: "-145px", marginLeft: "-450px", position: "relative", top: "40px" }} />
              </Button>
              {/* </div> */}
              <div className="Popup_bill">
                <h1 style={{ marginTop: "15px" }} >Payment summary</h1>
                <div onClick={() => backHome('false')}>
                  <Buttons Buttondata={MButton} />
                </div>
                <img
                  src={payimg}
                  alt=""
                  style={{ width: "45px", height: "45px", marginTop: "10px" }}
                />
                <h3>Selected customer</h3>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  color: "#035bc9",
                  marginRight: '110px'
                }}>
                  <p>Payment method</p>
                  <p>Customer name</p>
                  <p>Order date</p>
                  <p>Order time</p>
                </div>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  marginLeft: '120px',

                  position: 'relative',
                  color: "#035bc9",
                  bottom: '89px',
                }}>
                  <p>: Cash </p>
                  <p>: {customer?.customer.Name}</p>
                  <p>: {time.year}-{time.month}-{time.day}</p>
                  <p>: {time.hours}:{time.minutes}</p>
                </div>
                {/* <h3 style={{ color: "#035bc9", marginTop: "10px" }}>
                  Payment Method : Cash
                </h3> */}
              </div>
              {/* <div>
                    <h3
                      style={{
                        color: "#035bc9",
                        marginLeft: "35px",
                        marginBottom: "30px",
                        marginRight: "10px",
                      }}>
                      Customer Name: {customer?.Name}
                    </h3>
              </div> */}
              {Array.isArray(bproducts) &&
                bproducts.map((item) => (
                  <div style={{ display: "grid", marginTop: '-20px' }} key={item._id.$oid}>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          maxWidth: "220px",
                          minWidth: "190px",
                          flexDirection: "column",
                          height: "240px",
                          justifyContent: "space-around",
                        }}
                      >
                        <div style={{ display: 'flex', marginLeft: '-42px', marginTop: '-10px' }} >
                          <Checkbox onClick={() => clickdata()} />
                          <p style={{ marginTop: '10px', fontSize: '18px' }}>Outstanding balance:</p></div>
                        <p style={{ fontSize: '18px', }}>Order price:</p>
                        <p style={{ fontSize: '18px', }}>Discount:</p>
                        <p style={{ fontSize: '18px', }}>Final price:</p>
                        <p style={{ fontSize: '18px', }}>Received price:</p>
                        <p style={{ fontSize: '18px', }}>Remaining price:</p>
                      </div>
                      <div style={{
                        display: "flex",
                        width: "",
                        flexDirection: "column",
                        height: "240px",
                        marginBottom: "10px",
                        justifyContent: "space-around",
                      }}>
                        <img
                          src={money}
                          alt=""
                          style={{ height: "35px", width: "50px" }}
                        />
                        <img
                          src={money}
                          alt=""
                          style={{ height: "35px", width: "50px" }}
                        />
                        <img
                          src={money}
                          alt=""
                          style={{ height: "35px", width: "50px" }}
                        />
                        <img
                          src={money}
                          alt=""
                          style={{ height: "35px", width: "50px" }}
                        />
                        <img
                          src={money}
                          alt=""
                          style={{ height: "35px", width: "50px" }}
                        />
                        <img
                          src={money}
                          alt=""
                          style={{ height: "35px", width: "50px" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "240px",
                          justifyContent: "space-around",
                        }}>
                        <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>
                          {roundAmount(osb())} QAR</p>
                        <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>
                          {roundAmount(item.totalAmount)} QAR
                        </p>
                        <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>
                          {roundAmount(item.discount)} QAR
                        </p>
                        <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>
                          {roundAmount(finalamount())} QAR
                        </p>
                        {/* <p style={{ color: "#D70015",fontSize: "20px", fontWeight: "bold" }}>
                          value={discount}
                        </p> */}
                        <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>
                          <input
                            className="psinput"
                            value={receivedAmount}
                            type="number"
                            step="any"
                            onChange={handleSearch}
                          /> QAR
                        </p>
                        <p style={{ color: "#D70015", fontSize: "20px", fontWeight: "bold" }}>
                          {roundAmount(Number(finalamount()) - receivedAmount)} QAR
                        </p>
                      </div>
                    </div>
                    {/* <div className="psview">
                      <h3 className="delivery-date">Delivery Date : </h3>
                      <Input
                        type="Date"
                        value={DeliveryDate}
                        onChange={(e) => setDeliveryDate(e.target.value)}
                        className="input-date"></Input>
                    </div> */}
                  </div>
                ))}
              <div className="Product_data_save">
                <a href="/v1/home">
                  <div>
                    <Buttons Buttondata={CButton} />
                  </div>
                </a>
                <div onClick={ordersccuess}>
                  <Buttons Buttondata={BButton} />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Paycash;
