import React from "react";
import { PuffLoader } from "react-spinners";
import "./Loading.css";

function Loadingscr() {
  return (
    <div className="modal1">
      <div className="overlay1"></div>
      <div className="modal-content1">
        <PuffLoader id="Spinner" color="#EAEFDC" cssOverride={{}} size={100} speedMultiplier={1} />
      </div>
    </div>
  );
}

export default Loadingscr;
