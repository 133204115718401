import * as React from "react";
import "./Bill.css";
import axios from "axios";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
interface ProfileData {
  _id: { $oid: string };
  address: string;
  date_of_birth: string;
  name: string;
  phone_number: string;
  organization_name: string;
  user_name: string;
}
function Billofmaterial() {
  const token = localStorage.getItem("jsonwebtoken");
  const [data, setData] = React.useState<ProfileData | undefined>(undefined);
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        if (token) {
          const res = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/pos/profile`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("Response from API:", res.data);
          const profileData: ProfileData = res.data.message;
          console.log("Profile Data:", profileData);
          setData(profileData);
        } else {
          console.error("No token found in local storage.");
        }
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, [token]);
  return (
    <div id="displaybom" className="billover">
      <div className="hedder">Invoice</div>
      <div className="Details_overall">
        <div className="detail_color">
          Company Details :
          <p style={{ marginLeft: "10px" }}>
            <b>{data?.organization_name}</b>
            <br />
            {data?.address}
            <br />
            {data?.phone_number}
          </p>
        </div>
        <div className="detail_color">
          <p>Orderid:</p>
          <p>Orderdate:</p>
          <p>Delivery date:</p>
          <p>Order status:</p>
        </div>
        <div className="detail_color">
          <p>Customername:</p>
          <p>Ph.Number:</p>
          <p>Email id:</p>
          <p>Address:</p>
        </div>
        <div className="detail_color">
          <p>Agent name:</p>
          <p>Agent ph.no:</p>
        </div>
      </div>
      <div className="body_of_bill">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 590 }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ background: "none" }} align="center">
                  S.No
                </TableCell>
                <TableCell style={{ background: "none" }} align="center">
                  Products
                </TableCell>
                <TableCell style={{ background: "none" }} align="center">
                  Price
                </TableCell>
                <TableCell style={{ background: "none" }} align="center">
                  No.of quentity
                </TableCell>
                <TableCell style={{ background: "none" }} align="center">
                  Tax
                </TableCell>
                <TableCell style={{ background: "none" }} align="center">
                  Total price
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableCell align="center">1</TableCell>
              <TableCell align="center">2</TableCell>
              <TableCell align="center">3</TableCell>
              <TableCell align="center">4</TableCell>
              <TableCell align="center">5</TableCell>
              <TableCell align="center">6</TableCell>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default Billofmaterial;
