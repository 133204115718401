import { TextField } from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import Buttons from "../../Common_compound/Buttons";
import { Flip, ToastContainer, toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../axiosConfig";
import { AxiosError } from "axios";
interface Props {
  id: string;
  phone: string;
  name: string;
  email: string;
  address: string;
  address2: string;
  address3: string;
}
const Editcustomer: React.FC<Props> = ({
  id,
  name,
  phone,
  email,
  address,
  address2,
  address3,
}) => {
  const [Name, setName] = useState("");
  const [Phonenumber, setPhonenumber] = useState("");
  const [Email, setEmail] = useState("");
  const [Adress, setAdress] = useState("");
  const [confirmemail, setconfirmemail] = useState("");
  const [customeraddress1, setCustomeraddress1] = useState<string>("");
  const [customeraddress2, setCustomeraddress2] = useState<string>("");
  const [customeraddress3, setCustomeraddress3] = useState<string>("");
  const [visible, setvisible] = useState<boolean>(false);
  const phonenoref = useRef<HTMLInputElement>(null);
  const emailref = useRef<HTMLInputElement>(null);
  const streetsref = useRef<HTMLInputElement>(null);
  const statesref = useRef<HTMLInputElement>(null);
  const pincoderef = useRef<HTMLInputElement>(null);

  const handlenamesKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      phonenoref.current?.focus();
    }
  };
  const handlePhonenoKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      emailref.current?.focus();
    }
  };
  const handleemailKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      streetsref.current?.focus();
    }
  };
  const handlestreetsKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      statesref.current?.focus();
    }
  };
  const handleaddressKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      pincoderef.current?.focus();
    }
  };
  const handlepincodesKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      if (event.key === "Enter") {
        if (
          Name !== name ||
          Phonenumber !== phone ||
          Email !== email ||
          customeraddress1 !== address ||
          customeraddress2 !== address2 ||
          customeraddress3 !== address3
        ) {
          if (Name && Phonenumber) {
            update();
          } else {
            toast.error("Please fill in required fields.");
          }
        }
      }
    };
  };
  useEffect(() => {
    console.log("Initial Address:", address);
    console.log("Initial Address2:", address2);
    console.log("Initial Address3:", address3);
    setName(name);
    setPhonenumber(phone);
    setEmail(email);
    setCustomeraddress1(address);
    setCustomeraddress2(address2);
    setCustomeraddress3(address3);
  }, [address, address2, address3, email, name, phone]);

  useEffect(() => {
    const isDataChanged =
      Name !== name ||
      Phonenumber !== phone ||
      Email !== email ||
      customeraddress1 !== address ||
      customeraddress2 !== address2 ||
      customeraddress3 !== address3;

    setvisible(isDataChanged && Phonenumber.length >= 8 && Name.trim() !== "");
  }, [Name, Phonenumber, Email, customeraddress1, customeraddress2, customeraddress3]);

  const validateEmail = async (email: string) => {
    if (email.trim() === "") return true;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      toast.error("Please enter a valid email address with @ and a domain (eg., .co, .com).", { theme: "colored", autoClose: 2000 });
      return false;
    }
    try {
      const response = await api.get(`/app/v1/pos/customer/email-check?email=${email}`);
      console.log(response.data);
      if (response.data && response.data.exists) {
        toast.error("Email already exists", { theme: "colored", autoClose: 2000 });
        return false;
      }
      return true;
    } catch (error) {
      console.error("Error validating email:", error);
      toast.error("Error checking email. Please try again.");
      return false;
    }
  };
  const update = async () => {
    if (!Name.trim() || !Phonenumber.trim()) {
      toast.error("Please fill in the required fields: Name and Phone Number.", { theme: "colored", autoClose: 2000 });
      return;
    }
    const isEmailValid = await validateEmail(Email);
    if (!isEmailValid) {
      return;
    }
    try {
      const data = {
        Name: Name,
        phone_number: Phonenumber,
        Email: Email,
        Address: {
          address: customeraddress1,
          customeraddress2: customeraddress2,
          customeraddress3: customeraddress3,
        },
      };
      api.put(`/app/v1/pos/customer/${id}`, data).then((res) => {
        toast.success("Customer Updated successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
    } 
    catch (error) {
      console.error("Error updating customer:", error);
    
      // First check if error is an AxiosError
      if (error instanceof AxiosError && error.response && error.response.data) {
        const errorMessage = error.response.data.message;
    
        // Display toast messages based on the error message
        if (errorMessage.includes("Email already exists")) {
          toast.error("Email already exists", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (errorMessage.includes("Phone Number already exists")) {
          toast.error("Phone Number already exists", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error("Error updating customer. Please try again.");
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
    
  };
  const customeremailid = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isValidEmail = (value: string): boolean => {
      if (value.trim() === "") return false;
      if (!value.includes("@")) return false;
      const validDomains = [".com", ".in", ".co"];
      const hasValidDomain = validDomains.some((domain) =>
        value.endsWith(domain)
      );
      return hasValidDomain;
    };
    const emails = isValidEmail(value);
    if (emails) {
      setEmail(value);
      setconfirmemail(value);
    } else {
      setEmail(value);
    }
  };
  const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    value = value.replace(/\D/g, "");
    if (value.length > 8) {
      value = value.slice(0, 8);
    }
    event.target.value = value;
    setPhonenumber(value);
  };
  const handlenameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value.slice(0, 25));
  };

  const NButton = {
    Blue: "blue",
    name: "Update",
  };
  const FButton = {
    Cancel: "red",
    name: "Cancel",
  };
  const KButtonerr = {
    error: "blue",
    name: "update",
  };
  const confirm = () => {
    if (visible === true) {
      return (
        <div id="update_btn" style={{ marginLeft: "5px" }} onClick={update}>
          <Buttons Buttondata={NButton} />
        </div>
      );
    } else {
      return (
        <div id="confirm_btn">
          <Buttons Buttondata={KButtonerr} />
        </div>
      );
    }
  };
  return (
    <div className="modals">
      <div className="overlays"></div>
      <div className="scroll-button">
        <div className="modal-contents">
          <ToastContainer />
          <div>
            <h1 className="Edit_page_Name">
              Edit customer
              <button
                onClick={() => window.location.reload()}
                className="cancel-icon-Editcustomer"
              >
                <CloseIcon />
              </button>
            </h1>
            <div className="open-poppuscreen">
              <TextField
                id="Name"
                className="Inputusername"
                label={
                  <span>
                    Name <span style={{ color: '#f04141', fontSize: "15px" }}>*</span>
                  </span>
                }
                variant="outlined"
                value={Name}
                onChange={handlenameChange}
                onKeyPress={handlenamesKeyPress}
              />
              <TextField
                id="phoneno"
                label={
                  <span>
                    Phone number <span style={{ color: '#f04141', fontSize: "15px" }}>*</span>
                  </span>
                }
                variant="outlined"
                className="inputphone"
                value={Phonenumber}
                onChange={handlePhoneChange}
                onKeyPress={handlePhonenoKeyPress}
                inputRef={phonenoref}
              />
            </div>
            <div>
              <TextField
                // error={!!emailerror}
                id="Email"
                label={
                  <span>
                    Email (Optional)
                  </span>
                }
                className="Input-Email"
                variant="outlined"
                value={Email}
                onChange={customeremailid}
                onKeyPress={handleemailKeyPress}
                inputRef={emailref}
              />
            </div>
            <div className="Aaddress">
              <div className="input-typeaddress">
                <ul className="address-optional">
                  <p>Address (Optional):</p>
                  <TextField
                    id="standard-basic"
                    label="Street/Village"
                    variant="standard"
                    value={customeraddress1}
                    onChange={(e) => setCustomeraddress1(e.target.value)}
                    onKeyPress={handlestreetsKeyPress}
                    inputRef={streetsref}
                  />
                  <TextField
                    id="standard-basic"
                    label="District/State"
                    variant="standard"
                    value={customeraddress2}
                    onChange={(e) => setCustomeraddress2(e.target.value)}
                    onKeyPress={handleaddressKeyPress}
                    inputRef={statesref}
                  />
                  <TextField
                    id="standard-basic"
                    label="Pincode/Zipcode"
                    variant="standard"
                    value={customeraddress3}
                    onChange={(e) => setCustomeraddress3(e.target.value)}
                    onKeyPress={handlepincodesKeyPress}
                    inputRef={pincoderef}
                  />
                </ul>
              </div>
            </div>
            <div style={{ display: "flex", marginLeft: "100px" }}>
              <div
                id="cancel_btn"
                style={{ marginRight: "5px" }}
                onClick={() => window.location.reload()}
              >
                <Buttons Buttondata={FButton} />
              </div>
              {confirm()}
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editcustomer;
