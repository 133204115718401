import {
  Paper,
  TableContainer,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TablePagination,
} from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import React from "react";
interface Props {
  product: any | undefined;
}
const Tables: React.FC<Props> = ({ product }) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Paper sx={{ width: "97%" }} id="Table">
      <TableContainer sx={{ maxWidth: 2050 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ color: "white", fontSize: "large" }}>
                {product?.titlesno}
              </TableCell>
              <TableCell align="center" sx={{ color: "white", fontSize: "large" }}>
                {product?.titlename1}
              </TableCell>
              <TableCell align="center" sx={{ color: "white", fontSize: "large" }}>
                {product?.titlename2}
              </TableCell>
              {product?.titlename3 && (
                <TableCell
                  align="center"
                  sx={{
                    color: "white",
                    fontSize: "large",
                  }}
                >
                  {product?.titlename3}
                </TableCell>
              )}
              {product?.titlename4 && (
                <TableCell align="center" sx={{ color: "white", fontSize: "large" }}>
                  {product?.titlename4}
                </TableCell>
              )}
              {product?.titlename5 && (
                <TableCell align="center" sx={{ color: "white", fontSize: "large" }}>
                  {product?.titlename5}
                </TableCell>
              )}
              {product?.titlename6 && (
                <TableCell align="center" sx={{ color: "white", fontSize: "large" }}>
                  {product?.titlename6}
                </TableCell>
              )}
              {product?.titlename7 && (
                <TableCell align="center" sx={{ color: "white", fontSize: "large" }}>
                  {product?.titlename7}
                </TableCell>
              )}
              {product?.titlename8 && (
                <TableCell align="center" sx={{ color: "white", fontSize: "large" }}>
                  {product?.titlename8}
                </TableCell>
              )}
              {product?.titlename9 && (
                <TableCell align="center" sx={{ color: "white", fontSize: "large" }}>
                  {product?.titlename9}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "white" }}>
            {product?.S_no.length > 0 ? (
              (rowsPerPage > 0
                ? product.S_no.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : product.S_no
              ).map((sno: any, index: number) => (
                <TableRow key={sno} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="center">{sno}</TableCell>
                  <TableCell align="center">{product.bodyname1[sno - 1]}</TableCell>
                  <TableCell align="center">{product.bodyname2[sno - 1]}</TableCell>
                  {product?.titlename3 && <TableCell align="center">{product.bodyname3[sno - 1]}</TableCell>}
                  {product?.titlename4 && <TableCell align="center">{product.bodyname4[sno - 1]}</TableCell>}
                  {product?.titlename5 && <TableCell align="center">{product.bodyname5[sno - 1]}</TableCell>}
                  {product?.titlename6 && <TableCell align="center">{product.bodyname6[sno - 1]}</TableCell>}
                  {product?.titlename7 && <TableCell align="center">{product.bodyname7[sno - 1]}</TableCell>}
                  {product?.titlename8 && <TableCell align="center">{product.bodyname8[sno - 1]}</TableCell>}
                  {product?.titlename9 && <TableCell align="center">{product.bodyname9[sno - 1]}</TableCell>}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center" sx={{ fontStyle: 'italic', color: 'black' }}>
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={product?.S_no.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          slotProps={{
            select: {
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            },
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
    </Paper>
  );
};

export default Tables;
